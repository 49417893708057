<template>
    <badge :type="badgeType">
      {{ $t("SIGNATURES." + status) }}
    </badge>
  </template>
  
  <script>
  import {
    STATUS_CREATED,
    STATUS_UPDATED,
    STATUS_DELETED,
    STATUS_DRAFT,
    STATUS_SENT,
    STATUS_COMPLETED,
    STATUS_DECLINED,
    STATUS_EXPIRED,
  } from "@/constants/signatures";
  
  export default {
    name: "StatusBadge",
    props: {
      status: {
        type: String,
        required: true,
      },
    },
    computed: {
      badgeType() {
        switch (this.status) {
          case STATUS_CREATED:
          case STATUS_UPDATED:
          case STATUS_DRAFT:
            return "secondary";
          case STATUS_COMPLETED:
            return "success";
          case STATUS_DECLINED:
          case STATUS_EXPIRED:
            return "danger";
          case STATUS_SENT:
            return "warning";
          default:
            return "default";
        }
      },
    },
  };
  </script>
  