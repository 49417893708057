<template>
  <el-select
    v-model="contractModel"
    @change="contractChanged"
    autocomplete="new-password"
    :placeholder="$t('CONTRACTS.SELECT')"
    :filterable="filterable"
    :multiple="multiple"
    :disabled="disabled"
    remote
    :remote-method="getList"
    :loading="loading"
    :allow-create="allowCreate"
    class="w-100"
  >
    <el-option v-if="showAll" :value="null" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="contract in contracts"
      :key="contract.id"
      :value="contract.id"
      :label="`${contract.name}`"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import _ from "lodash";

export default {
  name: "contract-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allowCreate: {
      type: Boolean,
      default: false,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
    allowNone: {
      type: Boolean,
      default: false,
    },
    filterable: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    contract: {
      type: [String, Array],
      default: null,
    },
    selectedType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      sort: "-created_at",
      contractModel: this.contract, // Modèle pour la sélection
      contracts: [], // Liste des contracts récupérées
      loading: false, // Indicateur de contractment
      query: "", // Pour la recherche dynamique
    };
  },

  created() {
    this.getList(null, this.contract); // Charger les données initiales
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),
    // Fonction pour récupérer les contracts avec support pour tableau d'IDs ou ID unique
    async getList(query = null, ids = null) {
      try {
        this.loading = true;
        let params = {
          sort: this.sort,
          filter: {
            ...(query ? { search: query } : {}),
            ...(Array.isArray(ids) ? { ids: ids } : ids ? { id: ids } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.selectedType) {
          params.filter.contract_type = this.selectedType;
        }

        await this.$store.dispatch("contracts/list", params);
        const contractsArr = await this.$store.getters["contracts/list"];
        this.contracts = contractsArr;
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      } finally {
        this.loading = false;
      }
    },

    // Emission de l'événement lorsque la sélection change
    contractChanged(contract) {
      this.$emit("contractChanged", contract);
    },
  },

  watch: {
    contract(newContract) {
      if (newContract) {
        this.contractModel = newContract;
        // Si la valeur a changé, on recharge la liste avec l'ID ou les IDs
        if (newContract !== this.contractModel) {
          this.getList(null, newContract);
        }
      } else {
        this.contractModel = null;
      }
    },
    selectedType: {
      handler: "getListDebounced",
      immediate: true,
    },
  },
};
</script>
