export default {
  type: "signatures",
  code:null,
  email_message: null,
  status: null,
  object_type: null,
  object_id: null,
  signers: [],
  sent_at: null,
  meta: null,
  relationshipNames: ["contract", "object"],
  contract: {
    type: "contracts",
    id: null,
  },
};
