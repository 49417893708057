<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!signature">
      <span class="loader"></span>
    </span>
    <signature-form
      v-if="signature"
      :loading="loading"
      :signatureData="signature"
      :formErrors="formErrors"
      @signatureSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @cancelEdition="cancelEdition"
    />
  </div>
</template>

<script>
import SignatureForm from "../partials/SignatureForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";

export default {
  layout: "DashboardLayout",

  components: { SignatureForm },

  mixins: [alertLeave],

  props: {
    signatureId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      signature: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    cancelEdition() {
      this.$emit("cancelEdition");
    },
    async get() {
      this.loading = true;
      try {
        await this.$store.dispatch("signatures/get", this.signatureId);
        this.signature =
          this.$store.getters["signatures/signature"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: `Oops, something went wrong!`,
        });
        this.loading = false;
      }
    },

    async handleSubmit(signature) {
      this.loading = true;
      const signatureData = cloneDeep(signature);

      try {
        await this.$store.dispatch("signatures/update", signatureData);
        this.$notify({
          type: "success",
          message: this.$t("SIGNATURES.SIGNATURE_UPDATED"),
        });
        const signature = await this.$store.getters[
          "signatures/signature"
        ];
        this.$emit("onViewSignature", signature, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
