export const STATUS_CREATED = 'CREATED';
export const STATUS_UPDATED = 'UPDATED';
export const STATUS_DELETED = 'DELETED';
export const STATUS_DRAFT = 'DRAFT';
export const STATUS_SENT = 'SENT';
export const STATUS_COMPLETED = 'COMPLETED';
export const STATUS_DECLINED = 'DECLINED';
export const STATUS_EXPIRED = 'EXPIRED';
export const SIGNATURE_STATUS = {
    '':'',
    [STATUS_DELETED]: "DELETED",
    [STATUS_DRAFT]: "DRAFT",
    [STATUS_SENT]: "SENT",
    [STATUS_COMPLETED]: "COMPLETED",
    [STATUS_DECLINED]: "DECLINED",
    [STATUS_EXPIRED]: "EXPIRED",
};
export const STATUS_LIST_ARRAY = [
    STATUS_CREATED,
    STATUS_UPDATED,
    STATUS_DELETED,
    STATUS_DRAFT,
    STATUS_SENT,
    STATUS_COMPLETED,
    STATUS_DECLINED,
    STATUS_EXPIRED
]