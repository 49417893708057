export default {
  type: "contract-files",
  name: null,
  url: null,
  path: null,
  is_template: false,
  template_name: null,
  signature_page: 1,
  excerpt: '',
  meta: null,
  relationshipNames: ["contract"],
  contract: {
    type: "contracts",
    id: null,
  },
};
