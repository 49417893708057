export const DPA_CONTRACT_TYPE = 'DPA';
export const PPA_CONTRACT_TYPE = 'PPA';
export const DPA_PPA_CONTRACT_TYPE = 'DPA_PPA';
export const OTHERS_CONTRACT_TYPE = "OTHERS";
export const CONTRACT_TYPES = {
    '':'',
    [DPA_CONTRACT_TYPE]: "DPA",
    [PPA_CONTRACT_TYPE]: "PPA",
    [DPA_PPA_CONTRACT_TYPE]: "DPA_PPA",
    [OTHERS_CONTRACT_TYPE]: "OTHERS",
};
  