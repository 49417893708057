<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">
              {{ $t("SIGNATURES.SIGNATURES_LIST") }}
            </h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openSignatureCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_SIGNATURES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("SIGNATURES.ADD_SIGNATURE") }}
              </span>
            </base-button>

            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_SIGNATURES)"
              :objectType="'signatures'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <signature-list-table
        @onViewSignature="openSignatureViewModal"
        @onEditSignature="openSignatureEditModal"
        @onDeleteSignature="deleteSignature"
        @onSendSignature="sendSignature"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewSignatureModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewSignatureModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SIGNATURE_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SIGNATURES.VIEW_SIGNATURE") }}
              </h1>
            </div>

            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openSignature"
                :objectType="'signatures'"
                :objectId="openSignature.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <button class="close" @click="closeSignatureModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>

          <div class="resizable-wrapper-content-body">
            <view-signature-component
              v-if="openSignature"
              :signatureId="openSignature.id"
              @onEditSignature="openSignatureEditModal"
              @onDeleteSignature="deleteSignature"
              @onSendSignature="sendSignature"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditSignatureModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditSignatureModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SIGNATURE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SIGNATURES.EDIT_SIGNATURE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="view"
                  @click="openSignatureViewModal(openSignature)"
                >
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeSignatureModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-signature-component
              v-if="openSignature"
              :signatureId="openSignature.id"
              @onViewSignature="openSignatureViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddSignatureModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddSignatureModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'SIGNATURE'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("SIGNATURES.ADD_SIGNATURE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeSignatureModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-signature-component
              @onViewSignature="openSignatureViewModal"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SignatureListTable from "./partials/SignatureListTable.vue";
import EditSignatureComponent from "./components/EditSignatureComponent.vue";
import AddSignatureComponent from "./components/AddSignatureComponent.vue";
import ViewSignatureComponent from "./components/ViewSignatureComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SignatureListTable,
    NotificationSubscription,
    EditSignatureComponent,
    AddSignatureComponent,
    ViewSignatureComponent,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const signatureId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewSignatureModalOpened = false;
    let isEditSignatureModalOpened = false;
    let isAddSignatureModalOpened = false;
    let openSignature = null;
    if (signatureId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewSignatureModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditSignatureModalOpened = true;
      }
      openSignature = { id: signatureId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddSignatureModalOpened = true;
    }
    return {
      isViewSignatureModalOpened: isViewSignatureModalOpened,
      isEditSignatureModalOpened: isEditSignatureModalOpened,
      isAddSignatureModalOpened: isAddSignatureModalOpened,
      openSignature: openSignature,
      renderKey: 1,
    };
  },

  methods: {
    openSignatureCreateModal() {
      this.closeSignatureModal();
      this.isAddSignatureModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Signatures",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openSignatureViewModal(signature, reRender = false) {
      this.closeSignatureModal();
      this.openSignature = signature;
      this.isViewSignatureModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Signatures",
          query: { id: this.openSignature.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },

    openSignatureEditModal(signature) {
      this.closeSignatureModal();
      this.openSignature = signature;
      this.isEditSignatureModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Signatures",
          query: { id: this.openSignature.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeSignatureModal() {
      this.isAddSignatureModalOpened = false;
      this.isViewSignatureModalOpened = false;
      this.isEditSignatureModalOpened = false;
      this.openSignature = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Signatures",
          query: {},
        }).href
      );
    },

    async deleteSignature(signature) {
      const confirmation = await swal.fire({
        text: this.$t("SIGNATURES.DELETE_THIS_SIGNATURE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("COMMON.DELETE"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "signatures/destroy",
            signature.id
          );
          this.renderKey++;
          this.closeSignatureModal();
          this.$notify({
            type: "success",
            message: this.$t("SIGNATURES.SIGNATURE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },


    async sendSignature(signature) {
      const confirmation = await swal.fire({
        text: this.$t("SIGNATURES.SEND_FOR_SIGN_MESSAGE"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText:
          this.$t("COMMON.YES") + ", " + this.$t("SIGNATURES.SEND"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch(
            "signatures/send",
            signature
          );
          this.renderKey++;
          this.closeSignatureModal();
          this.$notify({
            type: "success",
            message: this.$t("SIGNATURES.SIGNATURE_SENT"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

  },
};
</script>
